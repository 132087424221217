import React from "react"
import Tab from 'components/molecules/Tab'
import get from 'lodash.get'

function TabDesktop(props) {
  const items = get(props, 'items');
  
  return (
    <Tab items={items} />
  )
}

export default TabDesktop