import React from "react"
import {HashLink} from 'react-router-hash-link'
import get from 'lodash.get'

import "./TabCard.scss"

function TabCard(props) {
  const title = get(props, 'title')
  const text = get(props, 'text')
  const url = get(props, 'url')
  const position = get(props, 'position')
  const color = get(props, 'color')
  const image = get(props, 'image')
  const alt = get(props, 'alt')

  return (
    <div className="TabCard tab-mobile">
      <img src={image} alt={alt || title} />
      <section className={`tab-mobile-${color}`}>
        <small>0{position + 1}</small>
        <h3>{title}</h3>
        <p>{text}</p>
        <HashLink to={url}>
          Saiba mais
          <i className="ml-2 icone-arrow-right" role="presentation"/>
        </HashLink>
      </section>
    </div>
  );
}

export default TabCard
