import React, { Fragment } from "react"
import { Row, Col } from "react-bootstrap"
import Slider from "react-slick"
import TabCard from 'components/molecules/TabCard'
import get from 'lodash.get'
import PropTypes from 'prop-types'

import "slick-carousel/slick/slick.css"

function TabMobile(props) {
  const { items } = props
  const _getTabColor = (index) => {
    switch (index) {
      case 0:
        return 'purple'
      case 1:
        return 'secondary-400'
      case 2:
        return 'secondary-500'
      case 3:
        return 'secondary-600'
      default:
        return `purple`
    }

  }
  const _items = Array.isArray(items) ? items.filter(i => i.entity).map((item, index) => {
    return { ...item, color: _getTabColor(index)}
  }) : []
  const acessibilityMode = get(props, 'acessibilityMode', false)
  const sliderSettings = {
    dotsClass: `slick-dots ${props.color ? props.color : "success"}`,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: false,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      }
    ],
  }

  const renderTabCard = Array.isArray(_items) && _items.map((tab, index) => {
    return (
      <TabCard
        key={get(tab, 'entity.uuid')}
        color={get(tab, 'color')}
        image={get(tab, 'entity.image.entity.images.large.url')}
        alt={get(tab, 'entity.image.entity.images.alt')}
        title={get(tab, 'entity.title')}
        text={get(tab, 'entity.subtitle')}
        url={get(tab, 'entity.link.url.path')}
        position={index}
      />
    )
  })

  return (
    <Row className="d-block d-md-none">
      <Col>
        { !acessibilityMode ?
          <Slider {...sliderSettings}>
            {renderTabCard}
          </Slider> :
          <Fragment>
            {renderTabCard}
          </Fragment>
        }
      </Col>
    </Row>
  );
}

TabMobile.propTypes = {
  acessibilityMode: PropTypes.bool
}

export default TabMobile
