import React, { useEffect, useRef, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { HashLink } from 'react-router-hash-link'
import get from 'lodash.get'

import './Tab.scss'
function Tab({ items }) {
  const [currentTab, setCurrentTab] = useState('tab-first')
  const tabControllerRef = useRef(null)

  // This will prevent the component from altering its height when someone browses through the items
  // This changes of height creates a weird effect of the page moving unexpectedly
  useEffect(() => {
    if (typeof window !== 'undefined' && tabControllerRef?.current) {
      const tabControllerElement = tabControllerRef.current
      tabControllerElement.style.height = window.getComputedStyle(tabControllerRef.current).height
    }
  }, [tabControllerRef])

  const _getTabName = (index) => {
    switch (index) {
      case 0:
        return 'tab-first'
      case 1:
        return 'tab-second'
      case 2:
        return 'tab-third'
      case 3:
        return 'tab-fourth'
      default:
        return `tab-${index}`
    }
  }

  const _tabChildren = (items) => {
    switch (items) {
      case 4:
        return 'children-4'
      case 3:
        return 'children-3'

      default:
        return 'children-4'
    }
  }
  const _items = Array.isArray(items)
    ? items
        .filter((i) => i.entity)
        .map((item, index) => {
          return { ...item, name: _getTabName(index) }
        })
    : []

  return (
    <Row>
      <Col className="d-none d-md-block">
        <div className={`tab ${_tabChildren(items.length)}`}>
          <div className="tab-controller" ref={tabControllerRef}>
            {Array.isArray(_items) &&
              _items.map((tab, i) => (
                <button
                  key={get(tab, 'entity.uuid')}
                  onMouseEnter={() => setCurrentTab(get(tab, 'name'))}
                  onFocus={() => setCurrentTab(get(tab, 'name'))}
                  onClick={() => setCurrentTab(get(tab, 'name'))}
                  className={classnames('tab-action w-100', get(tab, 'name'), {
                    active: get(tab, 'name') === currentTab,
                  })}
                >
                  <div className="tab-container">
                    <div className="tab-container-heading">
                      <p className="tab-container-heading-text">{`0${i + 1}`}</p>
                    </div>
                    <div className="tab-container-heading">
                      <h3 className="tab-container-heading-text">{get(tab, 'entity.title')}</h3>
                      <p>{get(tab, 'entity.subtitle')}</p>
                      <HashLink
                        id={get(tab, 'entity.id')}
                        to={get(tab, 'entity.link.url.path')}
                        aria-label={`Saiba mais sobre ${get(tab, 'entity.title')}`}
                        className="tab__link"
                      >
                        Saiba mais
                        <i className="ml-2 icone-arrow-right" role="presentation" />
                      </HashLink>
                    </div>
                  </div>
                </button>
              ))}
          </div>

          {Array.isArray(_items) &&
            _items.map((tab, i) => {
              if (tab.name === currentTab) {
                return (
                  <div key={i} className="tab-response">
                    <img
                      className="tab-img"
                      src={get(tab, 'entity.image.entity.images.wide.url')}
                      alt={get(tab, 'entity.image.entity.images.alt')}
                    />
                  </div>
                )
              }
            })}
        </div>
      </Col>
    </Row>
  )
}

Tab.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Tab
