import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Typography from 'components/atoms/Typography'
import TabDesktop from '../TabDesktop'
import TabMobile from '../TabMobile'
import get from 'lodash.get'

function LinkListLeftImage({data}) {
  const id = get(data, 'id')
  const title = get(data, 'title')
  const links = get(data, 'links')

  return (
    <Container id={id} className="link-list-left-image bg-secondary py-5" fluid="fluid">
      <Container>
        <Row>
          <Col>
            <Typography
              variant="h2"
              color="white"
              cssClass="title-semibold-lg mb-4"
            >
              {title}
            </Typography>
          </Col>
        </Row>
      </Container>
      <Container>
        <TabDesktop items={links} />
        <TabMobile items={links} />
      </Container>
    </Container>
  )
}

export default LinkListLeftImage
